import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeoSearchResult, GoogleSearchResponse, PlaceSearchResult } from './model/geo';
import { GqlQueryBuilder } from '../graphql.module/graphql.query.builder';
import { AutoCompleteResult } from './model/autoComplete';
import { QUERY_HYDRATION_KEYS } from 'src/app/infrastructure/query.hydration.keys';
import { GetAutoCompleteQuery, GetAutoCompleteQueryArgs } from "src/app/graphql/queries/get.auto.complete.query";
import { Router } from '@angular/router';
import { PlaceSearchCriteria } from 'src/app/infrastructure/model/placeSearchCriteria';
import { Loader } from '@googlemaps/js-api-loader';
import { GqlError } from '../graphql.module/model';
import { GraphQLService } from '../graphql.module/graphql.service';
import { ApplicationStateService } from '../application.state.module/application.state.service';
import { PlacebuzzSearchService } from './placebuzz.search.service';
import { CreateGqlQueryArguments } from 'src/app/graphql/create.query';

@Injectable({providedIn: 'root'})
export class GeoSearchService  {

  constructor(
    private gqlService: GraphQLService,
    private placebuzzSearchService: PlacebuzzSearchService,
    private applicationStateService: ApplicationStateService,
    private router: Router

  ) { }

  private validSearchResultTypes = [
    "street_address",
    "route",
    "intersection",
    "administrative_area_level_1",
    "administrative_area_level_2",
    "administrative_area_level_3",
    "administrative_area_level_4",
    "administrative_area_level_5",
    "colloquial_area",
    "locality",
    "sublocality",
    "neighborhood",
    "premise",
    "subpremise",
    "postal_code",
    "natural_feature",
    "airport",
    "bus_station",
    "transit_station",
    "park",
    "postal_town",
    "establishment",
    "point_of_interest"
];

loader =  new Loader({
  apiKey : "AIzaSyDD0KOQpQtgPPb0MAYHSoT0xykBM7ISEAQ",
  version:"weekly",
  libraries:["geometry", "places"]
});


  getSuggestions(query: string): Observable<any> {
    
    return new Observable<any[]>((observer) => {
      this.PerformGetAutocompleteQuery(query)
        .then((results) => {
            const suggestions = results;
            observer.next(suggestions);
            observer.complete();
        })
        .catch((error) => {
          console.error('Error fetching suggestions:', error);
          observer.next([]); // Return an empty array in case of an error.
          observer.complete();
        });
    });
  }

    NavigateToSearchResults(data: PlaceSearchResult, searchTypeId:number): void {    
      
      this.ResetRadiusIfNeeded(data.geoSearchResult);
      this.applicationStateService.SetLatestSearchArea(data.geoSearchResult.searchArea);
      
      this.placebuzzSearchService.PerformPlacebuzzSearch(data, searchTypeId).then(results => {
        this.applicationStateService.setLatestSearchCriteria(results.placeSearchCriteria);

        if (results.placeSearchCriteria.path.indexOf("?") > -1){
          //navigate with params
          const params = {};
          var url = results.placeSearchCriteria.path.split("?")[0];
          var queryString = results.placeSearchCriteria.path.split("?")[1];

          const keyValuePairs = queryString.split('&');

          keyValuePairs.forEach(keyValuePair => {
            const [key, value] = keyValuePair.split('=');
            params[key] = decodeURIComponent(value);
          });

          this.router.navigate([url], {queryParams: params, state:{placeSearchCriteria: results.placeSearchCriteria, listings: results, page: "search-results"} });
        }
        else{
          this.router.navigate([results.placeSearchCriteria.path], { state:{placeSearchCriteria: results.placeSearchCriteria, listings: results, page: "search-results"} });
        }
        
      });      
  }

  NavigateToSearchResultsWithFilterData(data: PlaceSearchResult, placeSearchCriteria: PlaceSearchCriteria): void {    
      
    this.ResetRadiusIfNeeded(data.geoSearchResult);
    this.applicationStateService.SetLatestSearchArea(data.geoSearchResult.searchArea);
    
    this.placebuzzSearchService.PerformPlacebuzzFullSearch(data, placeSearchCriteria).then(results => {
      this.applicationStateService.setLatestSearchCriteria(results.placeSearchCriteria);
      this.router.navigate([results.placeSearchCriteria.path], { state:{placeSearchCriteria: results.placeSearchCriteria, listings: results, page: "search-results"} });
    });      
}




  private ResetRadiusIfNeeded(geoSearchResult: GeoSearchResult) {
    let hasPlaceChanged = false;
    if (!this.applicationStateService.GetLatestSearchArea()) {
        hasPlaceChanged = true;
    }
    else if (this.applicationStateService.GetLatestSearchArea()) {
        hasPlaceChanged = this.applicationStateService.GetLatestSearchArea() !== geoSearchResult.searchArea;
    }
}




  private PerformGetAutocompleteQuery(input: string, showLoader:boolean = false): Promise<Array<AutoCompleteResult>> {
    var queryBuilder = new GqlQueryBuilder(QUERY_HYDRATION_KEYS.GetAutoCompleteQuery);
    queryBuilder.BuildMultiPartQuery([{
        queryConstant: GetAutoCompleteQuery, arguments: CreateGqlQueryArguments(GetAutoCompleteQueryArgs, [input])
    }]);

  return new Promise(resolve => {
        this.gqlService.ExecuteQuery(QUERY_HYDRATION_KEYS.GetAutoCompleteQuery, queryBuilder.query, queryBuilder.variables, () => { this.PerformGetAutocompleteQuery(input); }, this, showLoader, null,!showLoader).then(response => {
            var result: any = {};
            if (this.gqlService.ProcessResponse(result, response)) {
                 resolve(response.data.placeAutoComplete);
            }
        });
    });
}


  PerformGeocoderSearch(searchText: string, showLoader:boolean = false): Promise<PlaceSearchResult> {
    return new Promise(resolve => {

      
      this.PerformGetAutocompleteQuery(searchText, showLoader).then((results) => {
          if (results && results.length > 0) {
              var placeSearchResult: PlaceSearchResult = {
                geoSearchResult: {
                    latitude: results[0].latitude,
                    longitude: results[0].longitude,
                    type: results[0].types[0],
                    searchArea: searchText,
                    locationName: results[0].description,
                    localityId: results[0].localityId
                },

                errors: new Array<GqlError>()
              };
              resolve(placeSearchResult);
              return;
          }
          else{
            this.loader.load().then((google)  => {
              let geoCoder = new google.maps.Geocoder();
              geoCoder.geocode({
                  address: searchText + ", UK",
                  region: "uk"
              }, results => {
                  if (results) {
                      if (results.length === 0) { 
                       resolve (new PlaceSearchResult());
                       return;
                      }
                      var geoSearchResult = this.CreateGeoSearchResult(searchText, {
                          address_components: results[0].address_components,
                          geocoderGeometry: results[0].geometry,
                          types: results[0].types
                      });
                      
                      if (!geoSearchResult) {
                          resolve(new PlaceSearchResult());
                          return;
                      }
      
                      var placeSearchResult: PlaceSearchResult = {
                        geoSearchResult: geoSearchResult,
                        errors: new Array<GqlError>()
                      };
      
                      resolve(placeSearchResult);
                  }
              });
      
            })
          }
      });

      });

      
  } 

  private CreateGeoSearchResult(searchText: string, googleSearchResponse: GoogleSearchResponse): GeoSearchResult {
    let isValid = false;
    var self = this;
    googleSearchResponse.types.map(t => {
        isValid = (isValid || self.validSearchResultTypes.indexOf(t) > -1);
    });

    if (isValid) {
        let geometry = googleSearchResponse.geocoderGeometry ? googleSearchResponse.geocoderGeometry : googleSearchResponse.placeGeometry;
        return {
            searchArea: searchText,
            locationName: this.GetLocationFromResults(googleSearchResponse.address_components),
            latitude: geometry.location.lat(),
            longitude: geometry.location.lng(),
            type: this.GetType(googleSearchResponse.types),
        }
    }
    return null;
}

private GetLocationFromResults(addressComponents: Array<google.maps.GeocoderAddressComponent>): string {
  var locationArray = new Array<string>();
  for (var i = 0; i < addressComponents.length; i++) {
      var isValid = false;

      for (var iType = 0; iType < addressComponents[i].types.length; iType++) {
          isValid = this.validSearchResultTypes.find(v => v == addressComponents[i].types[iType]) != undefined;
          if (isValid) {
              locationArray.push(addressComponents[i].short_name);
              break;
          }
      }
  }
  var result = locationArray.join(", ");
  return result;
}

private GetType(types: Array<string>): string {
  if (types && types.length > 0) {
    if (types.length > 1) {
      if (types[0] == "postal_code" && types[1] == "postal_code_prefix") {
        return types[1];
      }
    }
    return types[0];
  }
  return ""; // Add a default return value here
}




}
