import { GqlError } from "../../graphql.module/model";

export class LatitudeLongitude {
    constructor(public latitude: number, public longitude: number) { }
}

export class AddressComponent {
    name: string;
    types: string[];
}

export interface GeoSearchResult {
    searchArea: string,
    locationName: string,
    latitude: number,
    longitude: number,
    type: string,
    localityId?: number
}

export class PlaceSearchResult {
    geoSearchResult: GeoSearchResult;
    errors: Array<GqlError>;

    constructor() {
        this.errors = new Array<GqlError>();
    }
}

export interface GoogleSearchResponse {
    address_components: Array<google.maps.GeocoderAddressComponent>;
    geocoderGeometry?: google.maps.GeocoderGeometry;
    placeGeometry?: google.maps.places.PlaceGeometry;
    types: string[];
}
