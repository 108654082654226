import { GqlQueryPart, GqlQueryArgument } from "src/app/services/graphql.module/model";
import { CreateGqlQueryArguments } from "../create.query";

// GetPlaceQuery



export const GetAutoCompleteQuery = `
placeAutoComplete(input: $input){
  description,
  latitude,
  longitude,
  types,
  localityId
}
`;
export const GetAutoCompleteQueryArgs: Array<GqlQueryArgument> = [
  { variableName: "$input", variableType: "String" }
];
export function GetAutoCompleteQueryPart(input: string): GqlQueryPart {
  var result: GqlQueryPart = {
    queryConstant: GetAutoCompleteQuery,
    arguments: CreateGqlQueryArguments(GetAutoCompleteQueryArgs, [input])
  };
  return result;
}
;
