import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GeoSearchService } from 'src/app/services/search.module/geosearch.service';
import { PbTextboxComponent } from '../pb-textbox/pb-textbox.component';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AutoCompleteResult } from 'src/app/services/search.module/model/autoComplete';

@Component({
  selector: 'pb-geo-input',
  templateUrl: './pb-geo-input.component.html',
  styleUrls: ['./pb-geo-input.component.scss'],
  standalone: true,
  imports:[FormsModule, CommonModule, PbTextboxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbGeoInputComponent implements OnInit {

  private queryChanged = new Subject<string>();

  constructor(
    private locationsService: GeoSearchService,
    private cd: ChangeDetectorRef,
    private eRef: ElementRef) {

    this.queryChanged
    .pipe(
      debounceTime(300), // Wait for 300 milliseconds pause in events
      distinctUntilChanged(), // Only emit if the value has changed
      filter(() => this.textboxFocussed),
      switchMap((query) => this.locationsService.getSuggestions(query)) // Switch to the new observable
    )
    .subscribe((data) => {
      this.suggestions = data;
      if (data.length === 0) {
        this.showSuggestions = false;
      }
      else{
        this.showSuggestions = true;
      }
      
      this.selectedIndex = -1;
      this.cd.detectChanges();
    });
  }

  ngOnInit(): void {
    
  } 

  @Input() query:string;
  showSuggestions = false;
  suggestions: string[] = [];
  lastSuggestion: any;
  selectedIndex = -1;
  _searchArea:AutoCompleteResult;
  textboxFocussed:boolean = false;
  
  onFocus(){
    this.textboxFocussed = true;
  }
  


  @Input() set searchArea(value:AutoCompleteResult){
    if (value){
      this.query = value.description;
      this.lastSuggestion = value;
      this._searchArea = value;
    }
  }
  
  get searchArea():AutoCompleteResult{
    return this._searchArea;
  }


  @Input() caption;
  @Input() placeholder;
  @Output() SearchAreaChanged = new EventEmitter<any>();
  @Output() queryChange = new EventEmitter<string>();
  @Output() blur = new EventEmitter<void>();


  onInputChange(value:string): void {
    this.query = value;
    this.queryChanged.next(this.query);
    this.queryChange.emit(this.query);
  }

  onBlur(): void {
    
      if (this.lastSuggestion){
        if (this.query !=this.lastSuggestion.description){
           this.blur.emit();  
        }
      }
      else{
        this.blur.emit();
      }
      this.textboxFocussed = false;
  }

  onSuggestionClick(suggestion: any): void {
    this.showSuggestions = false;
    this.lastSuggestion = suggestion;
    this.searchArea = suggestion;
    this.SearchAreaChanged.emit(suggestion);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      this.showSuggestions = false;
    }
  }


  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this.showSuggestions) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          this.navigateSuggestions(1);
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.navigateSuggestions(-1);
          break;
        case 'Enter':
          event.preventDefault();
          this.selectSuggestion();
          break;
        case 'Escape':
          event.preventDefault();
          this.showSuggestions = false;
          break;
      }
    }
    else{
      switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.showSuggestions = false;
        this.SearchAreaChanged.emit(this.query);
        break;
      case 'Escape':
        event.preventDefault();
        if (this.lastSuggestion){
          if (this.query !=this.lastSuggestion.description){
            this.query = this.lastSuggestion.description;
          }
        }
        break;
      }
    }
  }

  
  navigateSuggestions(step: number): void {
    const newIndex = this.selectedIndex + step;
    if (newIndex >= 0 && newIndex < this.suggestions.length) {
      this.selectedIndex = newIndex;
    }
  }

  selectSuggestion(): void {
    if (this.selectedIndex >= 0 && this.selectedIndex < this.suggestions.length) {
      //this.query = this.suggestions[this.selectedIndex];
      this.showSuggestions = false;
      this.cd.detectChanges();
    }
    else{
      this.showSuggestions = false;
      this.SearchAreaChanged.emit(this.query);
    }
  }



}
